import * as React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { solutionsPageTplData } from "../data/page-tpl-solutions.data";

import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import Hero from "../componentsSections/Hero";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import ImageSectionsGroup from "../components/ImageSectionsGroup";

// Homepage
const SolutionsPage = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = solutionsPageTplData(id);

  return (
    <Layout navbarVariant={pageData.hero.image ? "transparent" : undefined}>
      <Seo post={seoPageData} />

      {/* Rich Hero Section */}
      <Section className="cmb-lg-9 cmb-md-5 cmb-2 -mobile-full">
        <Hero {...pageData.hero} />
      </Section>

      {/* Image Sections */}
      <Section className="-mobile-full">
        <ImageSectionsGroup imageSections={pageData.imageSections} />
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default SolutionsPage;
